import { Attraction } from "typings/microserviceModels/destination-attractions-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { MediaAttribution } from "components/utility/MediaAttributionUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import * as React from "react";
import { AggregateRating } from "components/shared/Rating/AggregateRating";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import LazyLoad from "react-lazyload";
import { LazyLoaderPlaceHolder } from "components/utility/LazyLoaderPlaceholderWrapper";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLink } from "@egds/react-core/link";
import { UitkHeading, UitkText, UitkParagraph } from "@egds/react-core/text";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkSpacing } from "@egds/react-core/spacing";

export const AttractionCard = ({
  attraction,
  imageUrl,
  context,
  uitkCardClassNames,
  isCountry,
  isActivity,
  attractionsKeyHelper,
  attractionIndex,
}: {
  attraction: Attraction;
  imageUrl: string;
  context: ExtendedContextStore;
  uitkCardClassNames?: string;
  isCountry: boolean;
  isActivity: boolean;
  attractionsKeyHelper: ItemKeyHelper;
  attractionIndex: number;
}) => {
  const { formatText } = useLocalization();
  const {
    name,
    parentName,
    media,
    link,
    callToActionLink,
    callToActionLinkLocKey,
    recommendationScore,
    price,
  } = attraction;
  const description = attraction.description.replace(/\n/g, " ");
  // In the microservice country response...
  //  - source is mis-assigned to origin
  //  - mediaUrlOriginal is absent
  if (isCountry && media) {
    media.source = media.origin;
    media.mediaUrlOriginal = media.mediaUrl;
  }
  const attribution = media ? MediaAttribution(media, name) : null;
  const totalRatings = attraction.aggregateReviewsAndRatings?.totalRatings;
  const averageRating = attraction.aggregateReviewsAndRatings?.averageRating;
  let className = "uitk-card-content";
  if (isActivity) {
    className += " activity";
  }

  return (
    <UitkCard className={uitkCardClassNames} border>
      <UitkCardLink>
        <TrackedLink
          moduleName="TG.LP.Dest.Attractions"
          href={link}
          rfrr={isActivity ? "Activity.Card.Link" : "Poi.Card.Link"}
        >
          {name}
        </TrackedLink>
      </UitkCardLink>
      <UitkFigure ratio={UitkFigureAspectRatioType.R16_9}>
        <LazyLoad placeholder={<LazyLoaderPlaceHolder name={name} />} offset={250}>
          <BlossomImage placeholderImage alt={attribution?.attributionAltText || ""} src={imageUrl} />
        </LazyLoad>
        {attribution?.attributionText}
      </UitkFigure>
      <div className={className}>
        {isActivity ? (
          <ActivityCardTitleSegment name={name} price={price} recommendationScore={recommendationScore} />
        ) : (
          <PoiCardTitleSegment
            name={name}
            parentName={parentName}
            isCountry={isCountry}
            averageRating={averageRating}
            totalRatings={totalRatings}
            key={attractionsKeyHelper.next()}
            context={context}
          />
        )}
        {!isActivity && (
          <UitkCardContentSection>
            <UitkText size={300}>
              <div dangerouslySetInnerHTML={{ __html: `<p>${description}</p>` }} />
            </UitkText>
          </UitkCardContentSection>
        )}
        {callToActionLink && callToActionLinkLocKey && (
          <UitkCardContentSection padded>
            <UitkLink>
              <TrackedLink
                moduleName="DestinationAttractions"
                href={new HotelSearchLinkBuilder(callToActionLink).build(context)}
                rfrr={`findHotelsLink.${attractionIndex ? attractionIndex : ""}`}
                linkName="seeProperties"
              >
                {formatText(callToActionLinkLocKey)}
              </TrackedLink>
            </UitkLink>
          </UitkCardContentSection>
        )}
      </div>
    </UitkCard>
  );
};

export const PoiCardTitleSegment = ({
  name,
  parentName,
  isCountry,
  averageRating,
  totalRatings,
  context,
}: {
  name: string;
  parentName: string;
  isCountry: boolean;
  averageRating?: number;
  totalRatings?: number;
  context: ExtendedContextStore;
}) => {
  return (
    <UitkCardContentSection className="uitk-card-separator-bottom">
      <UitkLayoutGrid columns={1} className="uitk-layout-grid-gap-zero">
        <UitkLayoutGridItem>
          <UitkHeading tag="h4" size={6}>
            {name}
          </UitkHeading>
          {isCountry && Boolean(parentName) && (
            <UitkParagraph size={3} className="poiParentName">
              {parentName}
            </UitkParagraph>
          )}
        </UitkLayoutGridItem>
        <UitkLayoutGridItem>
          <AggregateRating rating={averageRating} reviewCount={totalRatings} context={context} />
        </UitkLayoutGridItem>
      </UitkLayoutGrid>
    </UitkCardContentSection>
  );
};

export const ActivityCardTitleSegment = ({
  name,
  price,
  recommendationScore,
}: {
  name: string;
  price: string;
  recommendationScore: number;
}) => {
  const { formatText } = useLocalization();
  const recommendationVisibilityThreshold = 70;

  return (
    <UitkCardContentSection className="activity-card">
      <UitkLayoutFlex className="text-segment" direction="column" justifyContent="space-between">
        <UitkSpacing margin={{ blockend: "three" }}>
          <UitkLayoutFlex>
            <UitkHeading tag="h4" size={6}>
              {name}
            </UitkHeading>
          </UitkLayoutFlex>
        </UitkSpacing>

        <UitkLayoutFlex justifyContent="space-between" direction="row-reverse">
          {Boolean(price) && (
            <UitkLayoutFlex direction="column">
              <UitkText size={600} weight="bold">
                {price}
              </UitkText>
              <UitkText size={200}>{formatText("destinationActivities.perAdult")}</UitkText>
            </UitkLayoutFlex>
          )}

          <UitkLayoutFlex direction="column">
            {recommendationScore > recommendationVisibilityThreshold && (
              <UitkText size={200}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatText("destinationActivities.travellersRecommend", recommendationScore),
                  }}
                />
              </UitkText>
            )}
          </UitkLayoutFlex>
        </UitkLayoutFlex>
      </UitkLayoutFlex>
    </UitkCardContentSection>
  );
};
