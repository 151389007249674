import * as React from "react";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { AttractionSectionProps } from "components/flexComponents/DestinationAttractions/typings";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { AttractionCard } from "./AttractionCard";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { UitkHeading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

export const AttractionSection = (props: AttractionSectionProps) => {
  const {
    attractions,
    context,
    attractionsKeyHelper,
    carouselAnalytics,
    title,
    isActivity,
    isCountry,
    showMaxAttractions,
    showCarouselOnDesktop,
    hideTitle,
  } = props;
  const { formatText } = useLocalization();
  const desktopAttractions =
    (showMaxAttractions && attractions) || attractions.slice(0, attractions.length >= 6 ? 6 : 3);

  const carousel = (
    <div className="AttractionSectionCarousel">
      {!hideTitle && title && (
        <UitkHeading tag="h3" size={5}>
          {title}
        </UitkHeading>
      )}
      <IsomorphicCarousel
        analytics={carouselAnalytics}
        carouselName={isActivity ? "Activities" : "Pois"}
        buttonText={{
          nextButton: formatText("carousel.item.next"),
          prevButton: formatText("carousel.item.prev"),
        }}
        itemsMaxHeight
        {...carouselProps}
      >
        {attractions.map((attraction, attractionIndex: number) => (
          <AttractionCard
            key={attractionsKeyHelper.next()}
            attraction={attraction}
            imageUrl={attraction.media?.mediaUrl || ""}
            context={context}
            isCountry={isCountry}
            isActivity={isActivity}
            attractionsKeyHelper={attractionsKeyHelper}
            attractionIndex={attractionIndex + 1}
          />
        ))}
      </IsomorphicCarousel>
    </div>
  );

  return (
    <Viewport>
      <ViewSmall>{carousel}</ViewSmall>
      <ViewLarge>
        {showCarouselOnDesktop ? (
          carousel
        ) : (
          <UitkSpacing margin={{ blockend: "three" }}>
            <div>
              {!hideTitle && title && (
                <UitkSpacing margin={{ blockend: "three" }}>
                  <UitkHeading tag="h3" size={4}>
                    {title}
                  </UitkHeading>
                </UitkSpacing>
              )}
              <UitkLayoutGrid columns={3} space="three">
                {desktopAttractions.map((attraction, attractionIndex: number) => (
                  <UitkLayoutGridItem key={attractionsKeyHelper.next()}>
                    <AttractionCard
                      attraction={attraction}
                      imageUrl={attraction.media?.mediaUrl || ""}
                      context={context}
                      uitkCardClassNames="attraction-card-desktop"
                      isCountry={isCountry}
                      isActivity={isActivity}
                      attractionsKeyHelper={attractionsKeyHelper}
                      attractionIndex={attractionIndex + 1}
                    />
                  </UitkLayoutGridItem>
                ))}
              </UitkLayoutGrid>
            </div>
          </UitkSpacing>
        )}
      </ViewLarge>
    </Viewport>
  );
};

export const carouselProps = {
  itemsVisible: { lg: 3, md: 2, sm: 1 },
  pageBy: 1,
  peek: true,
};
