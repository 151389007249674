import * as React from "react";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { DestinationAttractionsProps } from "./typings";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { AttractionSection } from "./AttractionSection";
import { observer } from "mobx-react";
import { withStores } from "src/stores";
import { DestinationAttractionsFlexModuleResult } from "src/typings/microserviceModels/destination-attractions-flex-module";
import { LazyLoad } from "components/utility/LazyLoad/LazyLoad";

export const DestinationAttractions = withStores(
  "flexModuleModelStore",
  "context",
  "analytics"
)(
  observer((props: DestinationAttractionsProps) => {
    const { templateComponent, flexModuleModelStore, context, analytics } = props;
    const { metadata, config } = templateComponent;
    const { hideTitle } = templateComponent.config;
    const model = flexModuleModelStore.getModel(metadata.id) as DestinationAttractionsFlexModuleResult | null;

    if (!model?.content) {
      return null;
    }

    const { content, ctaButtonToSRP, showMaxAttractions, showCarouselOnDesktop = false } = model;
    const attractionsKeyHelper = new ItemKeyHelper("top-attractions");
    const location = context.searchContext.location;
    const isCountry = location?.type === "country";
    const locationName = location?.localizedName || location?.name;
    let buttonLocationName = locationName;
    const locationType = location?.type;
    const regionId = location?.id;
    if ((locationType === "neighborhood" || locationType === "point_of_interest") && location?.parent?.localizedName) {
      buttonLocationName = location.parent.localizedName;
    }
    const { formatText } = useLocalization();

    const pois = content.pois.filter((attraction) => {
      return attraction.name && attraction.description;
    });

    const activities = content.activities.filter((attraction) => {
      return attraction.name && attraction.media?.mediaUrl?.length && attraction.description;
    });

    const activitiesTravelGuideUrl = content.activitiesTravelGuideUrl;
    const seeAllActivitiesRedirectURL =
      ctaButtonToSRP && regionId ? `/things-to-do/search?rid=${regionId}` : activitiesTravelGuideUrl;

    /* istanbul ignore next */
    const carouselAnalytics: AnalyticsConfig = {
      id: "TG.LP.Dest.Attractions",
      callback: (moduleId, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: moduleId,
          action: Action.SCROLL,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      },
    };

    /* istanbul ignore next */
    const buttonAnalytics: AnalyticsConfig = {
      id: "TG.LP.Dest.Attractions.Activities.Button",
      callback: (moduleId, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: moduleId,
          action: Action.CLICK,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      },
    };

    const seeAllButtonText =
      Boolean(buttonLocationName) &&
      formatText("destinationAttractions.city.activities.button.label", buttonLocationName);

    return (
      <LazyLoad context={context}>
        <div
          className="DestinationAttractions"
          id={metadata.id}
          data-fm={config.fmId}
          data-fm-title-id={config.fmTitleId}
        >
          {pois && pois.length > 0 && (
            <AttractionSection
              attractions={pois}
              attractionsKeyHelper={attractionsKeyHelper}
              title={content.poisSectionTitle}
              carouselAnalytics={carouselAnalytics}
              isActivity={false}
              isCountry={isCountry}
              locationName={locationName}
              context={context}
              showMaxAttractions={showMaxAttractions}
              showCarouselOnDesktop={showCarouselOnDesktop}
              hideTitle={hideTitle}
            />
          )}
          {activities && activities.length > 0 && (
            // There are never activities for countries.
            <>
              <AttractionSection
                attractions={activities}
                attractionsKeyHelper={attractionsKeyHelper}
                title={content.activitiesSectionTitle}
                carouselAnalytics={carouselAnalytics}
                isCountry={isCountry}
                isActivity
                locationName={locationName}
                context={context}
                showMaxAttractions={showMaxAttractions}
                showCarouselOnDesktop={showCarouselOnDesktop}
                hideTitle={hideTitle}
              />
              {Boolean(seeAllActivitiesRedirectURL) && (
                <UitkLayoutFlex justifyContent="center">
                  <UitkLayoutFlexItem>
                    <UitkSecondaryButton href={seeAllActivitiesRedirectURL} analytics={buttonAnalytics}>
                      {seeAllButtonText}
                    </UitkSecondaryButton>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
              )}
            </>
          )}
        </div>
      </LazyLoad>
    );
  })
);

export default DestinationAttractions;
